import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0];

export const dictionary = {
		"/(site)": [9,[3]],
		"/(main)/dashboard": [~4,[2]],
		"/(main)/dashboard/generer/[id]": [~5,[2]],
		"/(main)/dashboard/ny-pakke": [~6,[2]],
		"/(main)/demo": [7,[2]],
		"/(main)/kansellert": [8,[2]],
		"/lag-konto": [~10],
		"/logg-inn": [~11],
		"/logg-ut": [~12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';