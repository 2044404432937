import * as Sentry from "@sentry/sveltekit";
import { env } from "$env/dynamic/public";

// Sentry
Sentry.init({
  // URL
  dsn: env.PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.replayIntegration()],
});

export const handleError = Sentry.handleErrorWithSentry();
